<template>
    <div>
		<page-title :heading=heading :subheading=subheading></page-title>
		
		<!-- Searchable Datatable card -->
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="margin-top: 30px;">

			<a-col :span="24" :md="16" :lg="16" :xl="16" class="col-form pt-20 mt-0">
				<a-card :bordered="false" class="header-solid px-5" :bodyStyle="{padding: 0, paddingTop: '16px'}">
					<a-row class="pt-4">
						<a-col :span="24">
							<h5 class="font-semibold">Change Password</h5>
							<small>Once changing of password is successfully, you will be loged out so as tologin again with new password</small>
						</a-col>

						<a-col :span="24">
							<a-divider class="mt-1 pt-0"/>
						</a-col>
					</a-row>

					
					<div class="">
					
						<a-form
							id="components-form-demo-normal-login"
							:form="form"
							class="login-form pb-4"
							@submit.prevent="handleSubmit"
							:hideRequiredMark="true">


							<a-row :gutter="[24,24]" class="mt-0 pt-0">
								<a-col :span="24" :sm="24" :md="24" :lg="24" class="mt-0 pt-0">
									<a-form-item class="py-0 my-0" label="Temporary Password" :colon="false">
										<a-input
										v-decorator="[
										'oldPassword',
										{ rules: [{ required: true, message: 'Please enter your temporary password!' }, { len: 8, message: 'Invalid temporary password!' }] },
										]" type="password" placeholder="Temporary Password" />
									</a-form-item>
								</a-col>
							</a-row>

							<a-row :gutter="[24,24]" class="mt-0 pt-0">
								<a-col :span="24" :sm="24" :md="24" :lg="24" class="mt-0 pt-0">
									<a-form-item class="py-0 my-0" label="New Password" :colon="false">
										<a-input
										v-decorator="[
										'newPassword',
										{ rules: [{ required: true, message: 'Please enter your new password!' }, { min: 8, message: 'Password should have atleast 8 characters long!' }] },
										]" type="password" placeholder="Password" />
									</a-form-item>
								</a-col>
							</a-row>

							<a-row :gutter="[24,24]" class="mt-0 pt-0">
								<a-col :span="24" :sm="24" :md="24" :lg="24" class="mt-0 pt-0">
									<a-form-item class="py-0 my-0" label="Confirm New Password" :colon="false">
										<a-input
										v-decorator="[
										'newPasswordConfirmation',
										{ rules: [{ required: true, message: 'Please enter the new password again!' }, { min: 8, message: 'Password should have atleast 8 characters long!' }] },
										]" type="password" placeholder="Confirm New Password" />
									</a-form-item>
								</a-col>
							</a-row>

							<a-row :gutter="[24,24]" class="mt-0 pt-0 pb-4">
								<a-col :span="24" :sm="24" :md="24" :lg="24" class="mt-0 pt-0">
									<a-form-item class="my-0 mt-4 pt-2 pb-2"> 
										<a-button type="primary" color="#D15A1B" :loading="newItem.loading" html-type="submit" class="">
											Change Password
										</a-button>
									</a-form-item>
								</a-col>
							</a-row>


						</a-form>
							
					</div>
				</a-card>

			</a-col>
		</a-row>

    </div>
  </template>
  
  <script>
  
    import PageTitle from "../../Layout/Components/PageTitle.vue";

    const rules = {
      name: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
      ],
    };
  
    export default {
		components: {
			PageTitle,
		},
		data() {
			return {
            heading: 'Settings',
            subheading: 'Medicine Types',
            icon: 'pe-7s-drawer icon-gradient bg-happy-itmeo',
    
            fields: [ 'first_name', 'last_name', 'age' ],
            items: [
            { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
            { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
            { age: 89, first_name: 'Geneva', last_name: 'Wilson' }
            ],
            striped: false,
            bordered: false,
            outlined: false,
            small: false,
            hover: false,
            dark: false,
            fixed: false,
            footClone: false,

            message: '',

            medicines: [],
			methods: [
				{ uuid: "cash", name: "Cash" },
				{ uuid: "bank", name: "Bank" },
				{ uuid: "nhif", name: "NHIF" },
				{ uuid: "mobile-money", name: "Mobile Money" },
			],
            
            form: '',
            rules,


			newItem: {
				uuid: null,
				name: null,
				codeName: null,
				type: "company",
				description: null,
				isCreate: true,
				loading: false,
				showModal: false,
			},

            deleteDataItem: {
                uuid: null,
                showModal: false,
            },

            userDetails: {
                user: null,
                company: null
            },

            notify: {
                text: null,
                type: null,
            },
        }
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'newItemForm' });
		},
		created() {
			this.form = this.$form.createForm(this, { name: 'newItemForm' })
		},
		methods: {


			async handleSubmit() {

				this.form.validateFields((err, values) => {
					if ( !err ) {

						this.newItem.loading = true;

						let payload = values;
			
						let url = `${this.$BACKEND_URL}/auth/change-password`

						this.$AXIOS.post(url, payload).then(response => {

							if (response.status >= 200 && response.status < 210) {

								this.form.resetFields()
								
								this.newItem.loading = false;

								this.notify.text = response.data.message
								this.notify.type = "success"

								this.$notify(this.notify)

								this.logoutUser()

							}

							this.newItem.loading = false;

						}).catch(error => {
						
							this.newItem.loading = false;
				
							if(error.response != null && error.response.status == 401) {
								this.$router.push(`/`);
							}

							this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
							this.notify.type = "error"

							this.$notify(this.notify)
				
						});
					}

				})
			},

			async logoutUser() {
                await localStorage.setItem("zegeUser", null)
                await localStorage.setItem("zegeToken", null)

                this.$router.push(`/`);
            }


		},
    }
  </script>
  
  <style lang="scss">
  
  #textBtn, #textBtn:hover {
      border: none !important;
  }
</style>