var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('page-title',{attrs:{"heading":_vm.heading,"subheading":_vm.subheading}}),_c('a-row',{staticStyle:{"margin-top":"30px"},attrs:{"type":"flex","gutter":[24,24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-form pt-20 mt-0",attrs:{"span":24,"md":16,"lg":16,"xl":16}},[_c('a-card',{staticClass:"header-solid px-5",attrs:{"bordered":false,"bodyStyle":{padding: 0, paddingTop: '16px'}}},[_c('a-row',{staticClass:"pt-4"},[_c('a-col',{attrs:{"span":24}},[_c('h5',{staticClass:"font-semibold"},[_vm._v("Change Password")]),_c('small',[_vm._v("Once changing of password is successfully, you will be loged out so as tologin again with new password")])]),_c('a-col',{attrs:{"span":24}},[_c('a-divider',{staticClass:"mt-1 pt-0"})],1)],1),_c('div',{},[_c('a-form',{staticClass:"login-form pb-4",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-row',{staticClass:"mt-0 pt-0",attrs:{"gutter":[24,24]}},[_c('a-col',{staticClass:"mt-0 pt-0",attrs:{"span":24,"sm":24,"md":24,"lg":24}},[_c('a-form-item',{staticClass:"py-0 my-0",attrs:{"label":"Temporary Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'oldPassword',
										{ rules: [{ required: true, message: 'Please enter your temporary password!' }, { len: 8, message: 'Invalid temporary password!' }] },
										]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'oldPassword',\n\t\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please enter your temporary password!' }, { len: 8, message: 'Invalid temporary password!' }] },\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Temporary Password"}})],1)],1)],1),_c('a-row',{staticClass:"mt-0 pt-0",attrs:{"gutter":[24,24]}},[_c('a-col',{staticClass:"mt-0 pt-0",attrs:{"span":24,"sm":24,"md":24,"lg":24}},[_c('a-form-item',{staticClass:"py-0 my-0",attrs:{"label":"New Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'newPassword',
										{ rules: [{ required: true, message: 'Please enter your new password!' }, { min: 8, message: 'Password should have atleast 8 characters long!' }] },
										]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'newPassword',\n\t\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please enter your new password!' }, { min: 8, message: 'Password should have atleast 8 characters long!' }] },\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Password"}})],1)],1)],1),_c('a-row',{staticClass:"mt-0 pt-0",attrs:{"gutter":[24,24]}},[_c('a-col',{staticClass:"mt-0 pt-0",attrs:{"span":24,"sm":24,"md":24,"lg":24}},[_c('a-form-item',{staticClass:"py-0 my-0",attrs:{"label":"Confirm New Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
										'newPasswordConfirmation',
										{ rules: [{ required: true, message: 'Please enter the new password again!' }, { min: 8, message: 'Password should have atleast 8 characters long!' }] },
										]),expression:"[\n\t\t\t\t\t\t\t\t\t\t'newPasswordConfirmation',\n\t\t\t\t\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please enter the new password again!' }, { min: 8, message: 'Password should have atleast 8 characters long!' }] },\n\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Confirm New Password"}})],1)],1)],1),_c('a-row',{staticClass:"mt-0 pt-0 pb-4",attrs:{"gutter":[24,24]}},[_c('a-col',{staticClass:"mt-0 pt-0",attrs:{"span":24,"sm":24,"md":24,"lg":24}},[_c('a-form-item',{staticClass:"my-0 mt-4 pt-2 pb-2"},[_c('a-button',{attrs:{"type":"primary","color":"#D15A1B","loading":_vm.newItem.loading,"html-type":"submit"}},[_vm._v(" Change Password ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }